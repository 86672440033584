.partner-carousel-container {
  width: 100%;
  display: flex;
  position: relative;
  align-items: flex-start;
  flex-direction: column;
  justify-content: flex-start;
}
.partner-carousel-container1 {
  flex: 0 0 auto;
  width: 100%;
  height: fit-content;
  display: flex;
  overflow: hidden;
  align-items: center;
  justify-content: space-around;
}
.partner-carousel-container2 {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partner-carousel-image {
  width: 90%;
  object-fit: cover;
}
.partner-carousel-container3 {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partner-carousel-image1 {
  width: 100%;
  object-fit: cover;
}
.partner-carousel-container4 {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partner-carousel-image2 {
  width: 90%;
  object-fit: cover;
}
.partner-carousel-container5 {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partner-carousel-image3 {
  width: 90%;
  object-fit: cover;
}
.partner-carousel-container6 {
  flex: 0 0 auto;
  width: 200px;
  height: 200px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.partner-carousel-image4 {
  width: 100%;
  object-fit: cover;
}

@media(max-width: 991px) {
  .partner-carousel-container2 {
    width: 150px;
    height: 150px;
  }
  .partner-carousel-container3 {
    width: 150px;
    height: 150px;
  }
  .partner-carousel-container4 {
    width: 150px;
    height: 150px;
  }
  .partner-carousel-container5 {
    width: 150px;
    height: 150px;
  }
  .partner-carousel-container6 {
    width: 150px;
    height: 150px;
  }
}
@media(max-width: 767px) {
  .partner-carousel-container1 {
    width: auto;
    align-self: center;
    padding-top: var(--dl-space-space-unitandahalfunit);
    padding-left: var(--dl-space-space-unitandahalfunit);
    padding-right: var(--dl-space-space-unitandahalfunit);
    flex-direction: column;
    padding-bottom: var(--dl-space-space-unitandahalfunit);
    justify-content: center;
  }
  .partner-carousel-image1 {
    width: 90%;
  }
  .partner-carousel-image4 {
    width: 90%;
  }
}

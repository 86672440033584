.footer-footer {
  width: 100%;
  height: auto;
  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  background-image: linear-gradient(310deg,#141727,#3a416f);
}
.footer-container {
  color: var(--dl-color-gray-white);
  width: 100%;
  display: flex;
  padding: var(--dl-space-space-tripleunit);
  z-index: 1;
  max-width: 1320px;
  justify-content: space-between;
}
.footer-container1 {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-image {
  width: 150px;
  object-fit: cover;
}
.footer-text {
  font-size: .6rem;
}
.footer-container2 {
  display: flex;
  justify-content: space-between;
}
.footer-container3 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
}
.footer-text03 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text04 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text04:hover {
  color: var(--dl-color-gray-white);
}
.footer-text05 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text05:hover {
  color: var(--dl-color-gray-white);
}
.footer-text06 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text06:hover {
  color: var(--dl-color-gray-white);
}
.footer-container4 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: column;
}
.footer-text07 {
  font-weight: 700;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text08 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text08:hover {
  color: var(--dl-color-gray-white);
}
.footer-text09 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text09:hover {
  color: var(--dl-color-gray-white);
}
.footer-text10 {
  color: var(--dl-color-gray-800);
  transition: 0.3s;
  margin-bottom: var(--dl-space-space-halfunit);
}
.footer-text10:hover {
  color: var(--dl-color-gray-white);
}
.footer-container5 {
  flex: 0 0 auto;
  display: flex;
  align-items: flex-start;
  margin-left: var(--dl-space-space-sixunits);
  flex-direction: column;
  justify-content: space-around;
}
.footer-icon {
  fill: var(--dl-color-secondary-100);
  width: 24px;
  height: 24px;
}
.footer-icon2 {
  fill: var(--dl-color-secondary-100);
  width: 24px;
  height: 24px;
}
.footer-icon4 {
  fill: var(--dl-color-secondary-100);
  width: 24px;
  height: 24px;
}
.footer-root-class-name {
  background-image: linear-gradient(350deg,#82bb27,#005d63);
}
@media(max-width: 1200px) {
  .footer-root-class-name {
    background-image: linear-gradient(350deg,#82bb27,#005d63);
  }
}
@media(max-width: 991px) {
  .footer-container {
    padding: var(--dl-space-space-doubleunit);
  }
  .footer-root-class-name {
    background-image: linear-gradient(350deg,#82bb27,#005d63);
  }
}
@media(max-width: 767px) {
  .footer-container {
    flex-direction: column;
  }
  .footer-container1 {
    align-items: center;
    margin-bottom: var(--dl-space-space-doubleunit);
  }
  .footer-container4 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .footer-container5 {
    margin-left: var(--dl-space-space-doubleunit);
  }
  .footer-root-class-name {
    background-image: linear-gradient(210deg,#005d63,#82bb27);
  }
}
@media(max-width: 479px) {
  .footer-text {
    font-size: 0.6rem;
  }
  .footer-root-class-name {
    background-image: linear-gradient(210deg,#005d63,#82bb27);
  }
}
